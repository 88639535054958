import React from 'react';
import injectSheet from 'react-jss';

import Translate from './Translate';
import themeColors from '../styles/theme';

const styles = {
    otherGarminApps: {
        background: '#f8f8f8',
        height: '230px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '& img': {
            width: '100px',
            height: '100px'
        },
        '& > div:nth-of-type(1)': {
            textAlign: 'center',
            fontSize: '1.5rem',
            padding: '20px 0 30px 0',
            fontWeight: '500'
        },
        '& > div:nth-of-type(2)': {
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            fontSize: '1.1rem'
        }
    },
    attributionContent: {
        background: '#fff',
        height: '200px',
        textAlign: 'center',
        color: themeColors.footerText,
        justifyContent: 'center',
        '& > div': {
            flexDirection: 'column',
            display: 'flex',
            fontSize: '1.1rem',
            textAlign: 'center',
            justifyContent: 'center',
            height: '100%'
        }
    },
    app: {
        paddingRight: '40px'
    },
    '@media only screen and (max-width: 767px)': {
        otherGarminApps: {
            '& img': {
                width: '75px',
                height: '75px'
            },
            '& p': {
                fontSize: '0.9em'
            }
        },
        app: {
            paddingRight: '5px',
            width: '80px'
        }
    }
};

/**
 * Get other Garmin apps - lists pther available garmin connect apps
 */
class GetAppsSection extends React.Component {
    render() {
        const { classes, appList } = this.props;
        return (
            <div className={classes.appContent}>
                <div className={classes.otherGarminApps}>
                    <div>{<Translate ns="prelogin_pages" content="other_garmin_apps" />}</div>
                    <div>
                        {appList.map((app) => (
                            <div className={classes.app}>
                                <a href={app.url} title={app.title}>
                                    <img src={app.image} alt="" />
                                    <p>{app.title}</p>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={classes.attributionContent}>
                    <div>{<Translate ns="prelogin_pages" content="app_attribution_line" />}</div>
                </div>
            </div>
        );
    }
}

export default injectSheet(styles)(GetAppsSection);
